import type { RedfastMetadataResponse } from "~/types/Redfast";
import { useUserStore } from "~/stores/userStore";

export default function useRedfast() {
  const { $goodbits } = useNuxtApp();
  const userStore = useUserStore();

  const fetchRedfastMetaData = async (forceRefresh = false): Promise<void> => {
    if (!process.client) return;

    // Get current redfast ID and stored user email from localStorage
    const currentRedfastId = localStorage.getItem("redfast-id");
    const storedEmail = localStorage.getItem("redfast-user-email");
    const currentEmail = userStore.email;

    // Early return if we have a redfast ID and the user hasn't changed
    // and we're not forcing a refresh
    if (currentRedfastId && storedEmail === currentEmail && !forceRefresh) {
      return;
    }

    try {
      const response = await $goodbits<RedfastMetadataResponse>(
        "/subscriptions/v3/redfast-metadata",
      );

      // If we get a 204 response, clear the data and continue
      if (response.status === 204) {
        localStorage.removeItem("redfast-id");
        localStorage.removeItem("redfast-user-email");
        return;
      }

      // Store both the redfast ID and the current user email
      localStorage.setItem("redfast-id", response.data.accountCode);
      if (currentEmail) {
        localStorage.setItem("redfast-user-email", currentEmail);
      }
    } catch (err: any) {
      // Only throw error if it's not a 204 response
      if (err.response?.status !== 204) {
        throw new Error("Error fetching Redfast metadata:", err);
      }
    }
  };

  return {
    fetchRedfastMetaData,
  };
}
